<template>
  <div>
    <div class="maintitle">回收哥回收分类统计</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-date-picker v-model="timerange" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期"
                      :default-time="['00:00:00', '23:59:59']" style="width:250px;"
                      class="margin-big-left"></el-date-picker>
      <el-select v-model="slcthsg" class="margin-left">
        <el-option
            v-for="(item,index) in hsglist"
            :key="index"
            :label="item.name+'('+item.tel+')'"
            :value="item.uuid"
        ></el-option>
      </el-select>
      <el-button class="margin-left" @click="getlist">查询</el-button>
    </el-row>

    <el-table :data="list" border>
      <el-table-column align="center" prop="type" label="种类"></el-table-column>
      <el-table-column align="center" label="数量" prop="count"></el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  data() {
    return {
      hsglist: [],
      list: [],
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
      changeType: false,
      slcthsg: ''
    };
  },
  computed: {},
  methods: {
    getlist: function () {
      if (this.slcthsg == '') {
        this.$message({
          type: 'error',
          message: '请先选择回收人员'
        });
        return;
      }
      this.$axios({
        url: this.HOST + "/jiameng/huishouge/getTypeCount",
        method: "post",
        data: {
          uuid: this.slcthsg,
          timerange: this.timerange,
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
            date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate() +
            " 23:59:59"
        );
      }
    },
    gethsglist() {
      this.$axios({
        url: this.HOST + '/jiameng/huishouge/gethsgList',
        method: 'post',
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.hsglist = res.data.list;
        }
      });
    }
  },
  created: function () {
    this.gethsglist();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>